<template>
<!-- <b-tabs pills>
        <b-tab title="未入库">
            <companies ></companies>
        </b-tab>

        <b-tab title="已入库" active>
            <companies ></companies>
        </b-tab>
</b-tabs>   -->

<companies/>
</template>
  
<script>
  import {
    BTab,BTabs,BFormInput,BRow,BCol,BButton,BContainer
  } from 'bootstrap-vue'
  

import Companies from './Companies'
  export default {
      components: {
        Companies,
          BTabs,
          BTab,
          BRow,BCol,BButton,BFormInput,BContainer
      },
      data () {
          return {
            rawProjects: {},
            projects: {},
  
            projectsTable: [],
            users: [],
            isAddNewProjectSidebarActive: false,
            searchQuery: '',
  
          }
      },
      methods:{
          getProjects(){
              this.$axios.get("/collab/pm/get_projects").then(resp =>{
                  if (resp.data.status == "ok"){
                      this.rawProjects = resp.data.data.projects
                      this.projects = JSON.parse(JSON.stringify(this.rawProjects))
  
                      let projectsTable = []
                      Object.keys(this.projects).forEach(key => {
                          this.projects[key].forEach(kanban => {
                            projectsTable.push(kanban)
                          })
                      })            
                      this.projectsTable = projectsTable    
                    }
              })
          },
          getUsers(){
            this.$axios.get("/manage/users/get_users").then(resp =>{
              if (resp.data.status == "ok"){
                this.users = resp.data.data.users
              }
            })
          },
      },
      created(){

      }
  }
</script>