<template>
  <section>
    <company-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @get-companies="getCompanies"
    />

    <company-edit
      :is-edit-user-sidebar-active.sync="isEditUserSidebarActive"
      :com-data="comData"
      @get-companies="getCompanies"
    />

<!--     <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>展示</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条数据</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="搜索"
                v-b-tooltip.hover.bottom="`公司名称 税号 地址 座机 开户银行 银行账号`"
              />
<!--               <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">添加公司</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative text-nowrap"
        :items="filterCompanies"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <div class="text-nowrap">
                <p>
                <b-link
                    :to="{ name: 'vendors-company-view', params: { id: data.item.company_id }}"
                    class="font-weight-bold"
                    >{{ data.item.name }}
                  </b-link>
                </p>
            </div>
            </template>
          </b-media>
        </template>

        <template #cell(taxNumber)="data">
          <div class="text-nowrap">
            <p>{{ data.item.tax_number }}</p>
          </div>
        </template>

        <template #cell(address)="data">
          <div class="text-nowrap">
            <p>{{ data.item.address }}</p>
          </div>
        </template>

        <template #cell(telephone)="data">
          <div class="text-nowrap">
            <p>{{ data.item.telephone }}</p>
          </div>
        </template>

        <template #cell(openingBank)="data">
          <div class="text-nowrap">
            <p>{{ data.item.opening_bank }}</p>
          </div>
        </template>

        <template #cell(bankAccount)="data">
          <div class="text-nowrap">
            <p>{{ data.item.bank_account }}</p>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="viewCompany(data.item.company_id)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">查看详情</span>
            </b-dropdown-item>

            <b-dropdown-item  @click="isEditUserSidebarActive = true ;selectedCompany = data.item.company_id">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

<!--             <b-dropdown-item
            @click = "deleteCompany(data.item.company_id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">正在展示第 {{ dataMeta.from }} 到第 {{ dataMeta.to }} 条数据 （共{{ totalCompanies }}条数据） </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCompanies"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import UsersListFilters from './UsersListFilters.vue'
import CompanyAddNew from './CompanyAddNew.vue'
import CompanyEdit from './CompanyEdit.vue'

export default {
  components: {
    UsersListFilters,
    CompanyAddNew,
    CompanyEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data(){
    return {
      selectedCompany: null,
      isAddNewUserSidebarActive: false,
      isEditUserSidebarActive: false,
      companies: [],
      searchQuery: "",
      perPageOptions:[10, 25, 50, 100],
      perPage: 10,
      currentPage: 1,
      sortBy: "id",
      isSortDirDesc: true,
      fields:[
        {key:"name", label:"公司名称"},
        {key:"taxNumber", label:"税号"},
        {key:"address",label:"注册地址"},
        {key:"telephone", label:"电话"},
        {key:"openingBank", label:"开户银行"},
        {key:"bankAccount", label:"银行账号"},
        {key:"actions", label:"操作"}
      ]
    }
  },
  computed:{
    dataMeta(){
      return {
        from: (this.currentPage - 1) * this.perPage + 1,
        to: Math.min(this.currentPage * this.perPage, this.totalCompanies)
      }
    },
    totalCompanies : function(){
      return this.filterCompanies.length
    },
    comData(){
      return this.companies.filter(item => item.company_id == this.selectedCompany)[0]
    },
    filterCompanies(){
      return this.companies.filter((company)=>{
        return company.name.includes(this.searchQuery)
        || company.tax_number.includes(this.searchQuery)
        || company.address.includes(this.searchQuery)
        || company.telephone.includes(this.searchQuery)
        || company.opening_bank.includes(this.searchQuery)
        || company.bank_account.includes(this.searchQuery)
      })
    }
  },
  methods:{
    viewCompany(id){
      this.$router.push({name: "vendors-company-view", params: {id: id}})
    },
    getCompanies: function(){
      this.$axios.get("/vendors/companies/get_companies").then((res)=>{
        if (res.data.status == "ok"){
          this.companies = res.data.data.companies;
          this.companies.forEach(company => {
            Object.keys(company).forEach(key => {
              if (company[key] == null){
                company[key] = ""
              }
            })
          })
          
        }
      })
    },
    deleteCompany: function(company_id){
      this.$swal({
        title: '删除公司',
        text: "删除后无法撤销，你确定要删除该公司吗？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '是的，删除！',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.get("/vendors/companies/del_company/" + company_id).then((res)=>{
        if (res.data.status == "ok"){
          this.getCompanies();
          }
        })    
        }
      })
    }
  },
  created(){
    this.getCompanies()
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
